import {NewSimulationGatewayInterface} from '../../../corelogic/usecases/new-simulation/newSimulationGateway.interface';
import {Curve, Measurement, StatusEnum} from '../../../corelogic/models/types/new-simulation/curve/Curve';
import {SimulationForm} from '../../../corelogic/models/interfaces/SimulationForm';
import {CurveLoaded} from '../../../corelogic/models/types/new-simulation/curve/CurveLoaded';
import apiClient from './Api/ApiClient';
import {GeneralInfo} from '../../../corelogic/models/types/new-simulation/form/GeneralInfo';
import {
    OperationParameters
} from '../../../corelogic/models/types/new-simulation/form/operation-parameters/OperationParameters';
import {FinancialParameters} from '../../../corelogic/models/types/new-simulation/form/FinancialParameters';
import {InjectionPoint} from '../../../corelogic/models/types/new-simulation/form/InjectionPoint';
import {ConsumptionPoint} from '../../../corelogic/models/types/new-simulation/form/ConsumptionPoint';
import {Typology} from '../../../corelogic/models/types/new-simulation/form/Typology';
import {AddressGouv} from '../../../corelogic/models/types/new-simulation/external-api/AddressGouv';
import axios from 'axios';
import {SGEFetchedCDCStatus} from "../../../corelogic/usecases/typology-form/typologyFormReducer";
import LowCompletudeInput
    from "../../primary/ui/composition/form/load-curve-upload/completude-and-recalage-button/low-completeness/form/table/LowCompletudeInput";
import {
    ExternalInvestment
} from "../../primary/ui/composition/balance-sheet/external-investment-form/ExternalInvestment";
import {
    DistributionPriority
} from "../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionPriority";
import {
    DistributionShare
} from "../../../corelogic/models/types/new-simulation/form/operation-parameters/distribution/DistributionShare";
import {
    SurplusAllocationAPI, BackendSurplusAllocation
} from "../../../corelogic/usecases/operation-parameters-form/surplus_allocation/surplusAllocation.api";

import {
    seasonalityApi
} from "../../../corelogic/usecases/operation-parameters-form/seasonality/seasonality.api";
import {
    ungroupedPriorityApi
} from "../../../corelogic/usecases/operation-parameters-form/distribution/ungrouped-by-priority/ungroupedPriorityApi";
import {
    SurplusAllocation
} from "../../../corelogic/models/types/new-simulation/form/operation-parameters/surplus-allocation/SurplusAllocation";

export type SGEResults = {
    failuresByWeeks: SGEFetchedCDCStatus[],
    successfullResponseNumber: number,
    successRate: number,
}

export class APINewSimulationGateway implements NewSimulationGatewayInterface {
    async optimizePerimeter(simulationId: string, circleDiameter:number): Promise<{ lng: number; lat: number }>  {
        const res = await apiClient.post(`/optimize-perimeter/${simulationId}?acc_perimeter_diameter_in_kms=${circleDiameter.toString()}` )
        return Promise.resolve({lng : res.data.optimalDiskCenterLongitude, lat: res.data.optimalDiskCenterLatitude})
    }

    applyLowDataCompletion = async (measurement: Measurement[], table: LowCompletudeInput[], targetYear: number, simulationId: string, organisationId: string): Promise<Curve> => {
        const requestBody = {
            consumption_periods: table.map((row) => row.serialize()),
            type: "energy",
            nature: 'prod_network',
            step_length: 30,
            measurements: measurement,
            organisation_id: organisationId
        }
        const {data: curve} = await apiClient.post(`/load-curves/apply-scarce-data-completion?target_year=${targetYear.toString()}`, requestBody);
        let status: StatusEnum = this.normalizeStatus(curve.status)
        return new Curve(null, curve.measurements, curve.max_power, curve.total_energy, status, curve.max_n_of_days_with_contiguous_data, curve.step_in_minutes, curve.data_covers_target_year_with_30mn_steps)
    }
    applyAnnualCompletion = async (measurement: Measurement[], targetYear: number, simulationId: string, organisationId: string): Promise<Curve> => {
        const requestBody = {
            type: "power",
            nature: 'conso',
            step_length: 30,
            measurements: measurement,
            organisation_id: organisationId
        }
        const {data: curve} = await apiClient.post(`/load-curves/apply-completion?type=power&nature=conso&target_year=${targetYear.toString()}&organisation_id=${organisationId}&simulation_id=${simulationId}`, requestBody);
        let status: StatusEnum = this.normalizeStatus(curve.status)
        return Promise.resolve(new Curve(null, curve.measurements, curve.max_power, curve.total_energy, status, curve.max_n_of_days_with_contiguous_data, curve.step_in_minutes, curve.data_covers_target_year_with_30mn_steps))
    }

    applyRecalage = async (measurement: Measurement[], targetYear: number, simulationId: string, organisationId: string, recalageInput: string): Promise<Curve> => {
        const requestBody = {
            type: "power",
            nature: 'conso',
            step_length: 30,
            measurements: measurement,
            organisation_id: organisationId
        }
        const {data: curve} = await apiClient.post(`/load-curves/apply-energy-offset?type=power&nature=conso&target_year=${targetYear.toString()}&organisation_id=${organisationId}&simulation_id=${simulationId}&recalage=${recalageInput}`, requestBody);
        let status: StatusEnum = this.normalizeStatus(curve.status)
        return Promise.resolve(new Curve(null, curve.measurements, curve.max_power, curve.total_energy, status, curve.max_n_of_days_with_contiguous_data, curve.step_in_minutes, curve.data_covers_target_year_with_30mn_steps))
    }

    async getAddressFromAPI(address: string, type?: string): Promise<AddressGouv[]> {
        if (address.trim().length < 3) {
            return [];
        }
        const addType = type ? `&type=${type}` : ''
        const responseFromGouvApi = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${address}&limit=15${addType}`);
        return Promise.resolve(
            responseFromGouvApi.data['features'].map((addressesParams: any) => {
                const {properties, geometry} = addressesParams;
                return new AddressGouv(properties['label'], geometry['coordinates'][1], geometry['coordinates'][0], properties['context'], properties['name'], properties['type'], properties['city'], properties['postcode']);
            })
        );
    }

    async postSimulation(organisationId: string): Promise<SimulationForm> {
        const {data: newSimulationCreated} = await apiClient.post(`/simulations`, {organisation_id: organisationId});
        return new SimulationForm(newSimulationCreated.id, newSimulationCreated.organisation.id, newSimulationCreated.creation_timestamp, newSimulationCreated.update_timestamp, newSimulationCreated.created_by, newSimulationCreated.updated_by, newSimulationCreated.general_info || null, new Typology([], []), newSimulationCreated.operation_parameters ? newSimulationCreated.operation_parameters : null, newSimulationCreated.financial_parameters ? newSimulationCreated.financial_parameters : null);
    }

    loadUnsavedCurveFromSGE = async (prm: string, retries: string, delay: string, ifHolesFetch: boolean, startDate: string, endDate: string, organisationId: string, simulationId: string): Promise<{
        sgeResults: SGEResults;
        newCurve: Curve
    }> => {
        const {data: curve} = await apiClient.get(`/load-curves/sge?prm=${prm}&start_date=${startDate}&end_date=${endDate}&organisation_id=${organisationId}&simulation_id=${simulationId}&max_retries=${parseInt(retries)}&retry_delay=${parseInt(delay)}&ignore_empty_response=${ifHolesFetch}`);
        let status: StatusEnum = this.normalizeStatus(curve.status)
        const sgeResults: SGEResults = {
            failuresByWeeks: curve.result_status,
            successfullResponseNumber: curve.successful_responses_nbr,
            successRate: curve.success_rate
        }
        const curveWithSGEResults = {
            sgeResults: sgeResults,
            newCurve: new Curve(null, curve.measurements, curve.max_power, curve.total_energy, status, curve.max_n_of_days_with_contiguous_data, curve.step_in_minutes, curve.data_covers_target_year_with_30mn_steps)
        }
        return curveWithSGEResults
    }

    // to try when a pb happens on curve load

    async updateSimulation(newSimulation: SimulationForm): Promise<SimulationForm> {
        const {data: simulationUpdated} = await apiClient.put(`/simulations/${newSimulation._id}`, newSimulation.serialize());
        const generalInfo = simulationUpdated.general_info ? GeneralInfo.fromObject(simulationUpdated.general_info) : undefined;
        const updatedInjectionPoints: InjectionPoint[] =
            simulationUpdated.typology && simulationUpdated.typology.injection_points.length > 0
                ? simulationUpdated.typology.injection_points.map((updatedInjPoint: any) => InjectionPoint.fromObject(updatedInjPoint))
                : [];
        const updatedConsumptionPoints: any =
            simulationUpdated.typology && simulationUpdated.typology.consumption_points.length > 0
                ? simulationUpdated.typology.consumption_points.map((updatedConsPoint: any) => ConsumptionPoint.fromObject(updatedConsPoint))
                : [];
        const updatedTypology = new Typology(updatedInjectionPoints, updatedConsumptionPoints);
        const distributionPriorities = simulationUpdated.operation_parameters?.distribution_priorities ? simulationUpdated.operation_parameters.distribution_priorities.map((p: any) => new DistributionPriority(p.point_id, p.priority)) : [];
        const distributionShares = simulationUpdated.operation_parameters?.distribution_shares ? simulationUpdated.operation_parameters.distribution_shares.map((s: any) => new DistributionShare(s.point_id, s.shares)) : [];
        const seasonality = simulationUpdated.operation_parameters?.seasonality ? seasonalityApi.fromObject(simulationUpdated.operation_parameters.seasonality, updatedConsumptionPoints) : null;
        const surplus_allocation: BackendSurplusAllocation = {
                surplus_algorithm: simulationUpdated.operation_parameters?.surplus_algorithm,
                surplus_priorities: simulationUpdated.operation_parameters?.surplus_priorities
                }
        const surplusAllocation:SurplusAllocation = SurplusAllocationAPI.fromObjectSurplusAllocation(surplus_allocation, updatedInjectionPoints)
        const distributionByUngroupedPriority = ungroupedPriorityApi.fromObject(
            simulationUpdated.operation_parameters?.distribution_ungrouped_priorities,
            updatedInjectionPoints,
            updatedConsumptionPoints
        )
        return new SimulationForm(
            simulationUpdated.id,
            simulationUpdated.organisation.id,
            simulationUpdated.creation_timestamp,
            simulationUpdated.update_timestamp,
            simulationUpdated.created_by,
            simulationUpdated.updated_by,
            generalInfo,
            updatedTypology,
            simulationUpdated.operation_parameters ? new OperationParameters(simulationUpdated.operation_parameters.distribution_algorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation, distributionByUngroupedPriority) : undefined,
            simulationUpdated.financial_parameters
                ? FinancialParameters.fromObject(simulationUpdated.financial_parameters)
                : undefined,
            simulationUpdated.financing ? ExternalInvestment.fromObject(simulationUpdated.financing) : undefined,
        );
    }

    async loadCurveFromId(curveId: string): Promise<Curve> {
        const {data: curve} = await apiClient.get(`/load-curves/${curveId}`);
        const newCurve = new Curve(
            curve.id,
            curve.measurements,
            curve.max_power,
            curve.total_energy,
        );
        return Promise.resolve(newCurve);
    }

    async loadCurvesFromBody(type: string, nature: string, stepLength: number, body: any, organisationId: string, simulationId: string): Promise<CurveLoaded> {
        const {data: curve} = await apiClient.post(`/load-curves/file?type=${type}&nature=${nature}&step_length=${stepLength}&organisation_id=${organisationId}&simulation_id=${simulationId}`, body);
        return Promise.resolve(new CurveLoaded(curve.status, curve.status > 299 ? curve.detail : [], curve.id));
    }

    loadUnsavedCurveFromBody = async (rawBody: FormData, organisationId: string, simulationId: string): Promise<Curve> => {
        const {data: curve} = await apiClient.post(`/load-curves/enrich-file?type=power&nature=conso&step_length=30&organisation_id=${organisationId}&simulation_id=${simulationId}`, rawBody);
        let status: StatusEnum = this.normalizeStatus(curve.status)
        return Promise.resolve(new Curve(null, curve.measurements, curve.max_power, curve.total_energy, status, curve.max_n_of_days_with_contiguous_data, curve.step_in_minutes, curve.data_covers_target_year_with_30mn_steps))
    }

    async loadCurvesFromEnedis(postCode: string, accommodationType: string, heatingType: string, organisationId: string, simulationId: string): Promise<CurveLoaded> {
        const {data: curve} = await apiClient.get(`/load-curves/enedis-open-data`, {
            params: {
                postcode: postCode,
                accommodation_type: accommodationType,
                heating_type: heatingType,
                organisation_id: organisationId,
                simulation_id: simulationId,
            },
        });
        return Promise.resolve(new CurveLoaded(curve.status, curve.status > 299 ? curve.detail : [], curve.id));
    }

    async loadCurvesFromPVGIS(postCode: string, peakPower: number, loss: number, angle: number, orientation: number, organisationId: string, simulationId: string): Promise<CurveLoaded> {
        const {data: curve} = await apiClient.get(`/load-curves/pvgis`, {
            params: {
                postcode: postCode,
                peak_power: peakPower,
                loss: loss,
                angle: angle,
                orientation: orientation,
                organisation_id: organisationId,
                simulation_id: simulationId,
            },
        });
        return Promise.resolve(new CurveLoaded(curve.status, curve.status > 299 ? curve.detail : [], curve.id));
    }

    async saveLoadCurve(measurement: Measurement[], simulationId: string, organisationId: string): Promise<CurveLoaded> {
        const {data: curve} = await apiClient.post(`/load-curves/save`, {
                measurements: measurement,
                type: "power",
                nature: 'conso',
                organisation_id: organisationId,
            },
            {
                params: {
                    simulation_id: simulationId,
                }
            });
        return Promise.resolve(new CurveLoaded(curve.status, curve.status > 299 ? curve.detail : [], curve.id));

    }

    async getSimulationFromId(simulationId: string): Promise<SimulationForm> {
        const {data: simulationFetched} = await apiClient.get(`/simulations/${simulationId}`);
        const updatedInjectionPoints: any =
            simulationFetched.typology && simulationFetched.typology.injection_points.length > 0 ? simulationFetched.typology.injection_points.map((updatedInjPoint: any) => InjectionPoint.fromObject(updatedInjPoint)) : [];

        const updatedConsumptionPoints: any =
            simulationFetched.typology && simulationFetched.typology.consumption_points.length > 0
                ? simulationFetched.typology.consumption_points.map((updatedConsPoint: any) => ConsumptionPoint.fromObject(updatedConsPoint))
                : [];
        const updatedTypology = new Typology(updatedInjectionPoints, updatedConsumptionPoints);
        const generalInfo = simulationFetched.general_info
            ? GeneralInfo.fromObject(simulationFetched.general_info)
            : undefined;
        const distributionPriorities = simulationFetched.operation_parameters?.distribution_priorities ? simulationFetched.operation_parameters.distribution_priorities.map((p: any) => new DistributionPriority(p.point_id, p.priority)) : [];
        const distributionShares = simulationFetched.operation_parameters?.distribution_shares ? simulationFetched.operation_parameters.distribution_shares.map((s: any) => new DistributionShare(s.point_id, s.shares)) : [];
        const seasonality = simulationFetched.operation_parameters?.seasonality ? seasonalityApi.fromObject(simulationFetched.operation_parameters.seasonality, updatedConsumptionPoints) : null;
        const surplus_allocation: BackendSurplusAllocation = {
        surplus_algorithm: simulationFetched.operation_parameters?.surplus_algorithm,
        surplus_priorities: simulationFetched.operation_parameters?.surplus_priorities
        }
        const surplusAllocation:SurplusAllocation = SurplusAllocationAPI.fromObjectSurplusAllocation(surplus_allocation, updatedInjectionPoints)
        const distributionByUngroupedPriority = ungroupedPriorityApi.fromObject(simulationFetched.operation_parameters?.distribution_ungrouped_priorities, updatedInjectionPoints, updatedConsumptionPoints)

        return new SimulationForm(
            simulationFetched.id,
            simulationFetched.organisation.id,
            simulationFetched.creation_timestamp,
            simulationFetched.update_timestamp,
            simulationFetched.created_by,
            simulationFetched.updated_by,
            generalInfo,
            updatedTypology,
            simulationFetched.operation_parameters ? new OperationParameters(simulationFetched.operation_parameters.distribution_algorithm, distributionPriorities, distributionShares, seasonality, surplusAllocation, distributionByUngroupedPriority) : undefined,
            simulationFetched.financial_parameters
                ?  FinancialParameters.fromObject(simulationFetched.financial_parameters)
                : undefined,
            simulationFetched.financing ? ExternalInvestment.fromObject(simulationFetched.financing) : undefined,
            simulationFetched.target_year
        );
    }

    normalizeStatus = (status: string) => {
        switch (status) {
            case 'standard_completion_compliant' :
                return StatusEnum.STANDARD_COMPLETION_COMPLIANT;
            case 'valid' :
            case '12_months_complete' :
                return StatusEnum.VALID;
            case '1_week_complete' :
                return StatusEnum.ONE_WEEK_COMPLETE
            case 'rejected' :
            default :
                return StatusEnum.REJECTED;
        }
    }
}
