import {AuthenticationGatewayInterface} from "../../../../corelogic/usecases/authentication/authenticationGateway.interface";
import {Organisation} from "../../../../corelogic/models/types/simulations-display/Organisation";

export class AuthenticationInMemoryGateway implements AuthenticationGatewayInterface {

    logIn(credentials: { email: string; password: string }): any {
        return null;
    }
    async logOut(): Promise<any> {
        return null;
    }

    async getSession(): Promise<any> {
        const user = { id: 'id1', name : 'Tee Beau', email: 'user@enogrid.com'}
        const organisations:Organisation[] = [
            new Organisation('org1',  'Enogrid',  10, false),
            new Organisation('org2',  'Orga 2',  100, false)
        ]
        return {user, organisations};
    }

    updatePassword({oldPwd, newPwd}: { oldPwd: string; newPwd: string }): void {

    }
}
