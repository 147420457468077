import {useEffect} from "react";
import {useSelector} from "react-redux";
import {selectOrganisations, selectUser} from "../../../../../corelogic/usecases/authentication/authenticationSelector";
import {Organisation} from "../../../../../corelogic/models/types/simulations-display/Organisation";
import {v4 as uuid} from "uuid";

const CHATWOOT_BASE_URL = "https://app.chatwoot.com";
const CHATWOOT_WIDGET_ID = "#cw-widget-holder";

const chatwootSettings = {
    darkMode: "auto",
    hideMessageBubble: true,
    position: "right",
    locale: "fr",
    type: "standard",
    showPopoutButton: false,
};

export default function ChatwootWithProductFruitIntegration() {
    const user = useSelector(selectUser);
    const organisations = useSelector(selectOrganisations);
    const organisationsNames = organisations.map((o: Organisation) => o.name);
    const win = window as any;

    useEffect(() => {
        if (win.chatwootSDK) return;
        loadChatwootSDK()
    }, []);

    useEffect(() => {
        const openChatwoot = () => {
            // console.log("💬 Ouverture de Chatwoot");
            win.$chatwoot?.toggle("open");
        };
        const handleProductFruitInit = () => openChatwoot();

        initializeChatwoot()
        initializeProductFruit(handleProductFruitInit)

        return () => {
            // console.log("🧹 Nettoyage des écouteurs d'événements");
            window.removeEventListener("productfruits_button_ext_widget_init", handleProductFruitInit);
        };
    }, []);

    useEffect(() => {
        updateChatwootUser(user, organisationsNames)
    }, [user, organisationsNames]);


    return null;
}

const initializeProductFruit = (handleProductFruitInit: any) => {
    const win = window as any;


    const openProductFruitWhenChatwootClose = () => {
        // console.log("🔍 Surveillance de la fermeture de Chatwoot");
        const interval = setInterval(() => {
            const chatwootElement = document.querySelector(CHATWOOT_WIDGET_ID);
            if (!chatwootElement) return;

            clearInterval(interval);

            const observer = new MutationObserver(() => {
                if (chatwootElement.classList.contains("woot--hide")) {
                    // console.log("🎈 Ouverture de ProductFruits");
                    win.productFruits?.api?.inAppCenter.show();
                }
            });

            observer.observe(chatwootElement, {attributes: true, attributeFilter: ["class"]});

            return () => {
                // console.log("🛑 Arrêt de l'observation de Chatwoot");
                observer.disconnect();
            };
        }, 500);
    };

    window.addEventListener("productfruits_button_ext_widget_init", handleProductFruitInit);

    openProductFruitWhenChatwootClose();
}
const initializeChatwoot = () => {
    const win = window as any;

    // console.log("⚙️ Configuration des paramètres Chatwoot");
    win.chatwootSettings = chatwootSettings;
}
const loadChatwootSDK = () => {
    const win = window as any;

    // console.log("📥 Chargement du SDK Chatwoot");
    const script = document.createElement("script");
    script.src = `${CHATWOOT_BASE_URL}/packs/js/sdk.js`;
    script.defer = true;
    script.async = true;
    script.onload = () => {
        // console.log("🚀 Exécution du SDK Chatwoot");
        win.chatwootSDK.run({
            websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
            baseUrl: CHATWOOT_BASE_URL
        });
    };
    document.body.appendChild(script);
}
const updateChatwootUser = (user: any, organisationsNames: any) => {
    const unique_id = uuid();
    const win = window as any;

    if (!win.$chatwoot) {
        // console.warn("⚠️ Chatwoot n'est pas encore prêt !");
        return;
    }

    if (!user?.name || organisationsNames.length === 0) {
        // console.warn("⚠️ Impossible de définir l'utilisateur - Infos manquantes");
        return;
    }

    const loggedName = `${user.name}, ${user.email} (${organisationsNames.join(", ")})`;
    // console.log("🧒 Mise à jour de l'utilisateur dans Chatwoot: ", loggedName);

    win.$chatwoot.setUser(user.id || unique_id, {
        email: user.email,
        name: loggedName,
        avatar_url: "https://gravatar.com/avatar/8ea6382a4dfefafcd7052038530cb1a3?s=400&d=robohash&r=x"
    });
}